import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Rachel Caldwell" subtitle="Director" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "648px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/beff755b1ecbccbb076dcf3f5df36a26/6e52e/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAwb/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAGWzg+bJAtG9qvWi3Kjwyf/xAAeEAABAwUBAQAAAAAAAAAAAAACAAEDBBESEzMQIv/aAAgBAQABBQIBu8sGvyN7HUF8KEBZasmlHE4uqqOv/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBEx/9oACAEDAQE/AYpZUeGn/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQERL/2gAIAQIBAT8BV3gu7//EABwQAAEEAwEAAAAAAAAAAAAAAAABEBEhAjGRcf/aAAgBAQAGPwL0vrIJrbQmM0WnSDFlP//EAB0QAQACAgIDAAAAAAAAAAAAAAEAIRExEFFBcYH/2gAIAQEAAT8hwx2wRGFh0IlzW6zA1ZNL8R3KIJpMGPX0mfGo/pwAo3c//9oADAMBAAIAAwAAABAc8M7/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/EF7bZOiccv/EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQIBAT8QnowqsWIz/8QAHhABAAIDAAIDAAAAAAAAAAAAAQARIVFhMUFxkdH/2gAIAQEAAT8QBtyBdYpRo4eVKEajNzBvMCKdyqWl/syTuX94lvN/MWRtoQMd9lfa8q1yZUUo++RUHUu5ND7T/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/beff755b1ecbccbb076dcf3f5df36a26/6e52e/01.jpg",
              "srcSet": ["/static/beff755b1ecbccbb076dcf3f5df36a26/f93b5/01.jpg 300w", "/static/beff755b1ecbccbb076dcf3f5df36a26/b4294/01.jpg 600w", "/static/beff755b1ecbccbb076dcf3f5df36a26/6e52e/01.jpg 648w"],
              "sizes": "(max-width: 648px) 100vw, 648px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <p>{`We are pleased to present the PSI community with the 2019 - 2020 Annual report.`}</p>
    <p>{`There is no doubt that the 2019 - 2020 school year will be remembered as an extraordinary one. This was true, initially, for our many achievements, and ultimately for the crisis situation caused by COVID-19.`}</p>
    <p>{`As we continue to work towards realisation of our strategic plan goals (SPGs) (insert), this is also the first year that we are reporting on PSI’s Leading School Benchmarks (LSBs). These LSBs are intended as standards against which we will measure our performance as a leading international school in the world. While we identified a few areas where additional work is required, our overall results are strong.`}</p>
    <p>{`PSI’s transition to distance learning was swift and effective. Welcoming feedback from students, parents, faculty and staff, we continued to adapt our programmes in order to make them as robust and engaging as possible for our learners. A huge consideration was the emotional and social well being of all students. Our counsellors took a strong lead in this area and we shared a comprehensive website section filled with research, resources and tools. We also facilitated webinars and offered individual sessions. Well-being and balance (one of our three SPGs) will remain a focus for 2020 - 2021.`}</p>
    <p>{`As an IB World School, one of our key responsibilities is to commit to developing intercultural awareness, global citizenry and global engagement. I was particularly impressed this year by our MYP students’ Personal Projects and our PYP students’ Exhibition offerings, in this regard. Our students are well prepared to engage in research and taking action on challenging, real-world dilemmas, and they do it very well. I also want to recognise our Grade 12 students, whose last year at school took such an unexpected turn. We are proud of the resilience and fortitude they demonstrated and we are excited to hear back from them all as they begin their new adventures. When possible, we shall celebrate them all at a special alumni event dedicated to them.`}</p>
    <p>{`I would like to share my admiration for PSI’s Board of Governors. Led with humility and kindness by our Board Chair, Alex Munteanu, this group of dedicated parent volunteers readily shares its expertise and knowledge in order to provide strong strategic direction and fiduciary oversight. Our Board meets together on a monthly basis as a whole group, and works through its three main committees and ad hoc groups to provide effective governance. We were delighted to host a record number of participants (79) at our virtual annual general meeting this year. The evening allowed us to showcase our school’s achievements and to announce the results of a competitive election for our new Board members.`}</p>
    <p>{`The school’s educational leadership team rose to the many challenges created by the 2019 - 2020 situation. I feel privileged to work with such a dedicated and determined group of educators. In terms of our faculty, I was left speechless by their commitment and their readiness to significantly adapt their practices to meet our students’ needs. They have remained positive, collaborative and supportive throughout this period. Our staff members have also shown their tireless dedication to PSI.`}</p>
    <p>{`I would also like to share my thanks to our Parent Teacher Association, led by Jan Cairns, who, after many years as a PSI parent, will leave us this year...only, I hope, to become a valued and active alumna. The PTA was extremely active and successful and continued to provide much appreciated support for the community throughout the most difficult moments. What an outstanding community we have.`}</p>
    <p>{`Before I close, I would like to acknowledge and thank our departing principal, Patricia Puia. Patricia’s unwavering support for PSI over more than a decade has resulted in a stronger and better school. She leaves behind an important legacy for which we shall be forever grateful.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      